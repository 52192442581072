import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'vshc-optional-warning',
    templateUrl: './optional-warning.component.html',
    styleUrls: ['./optional-warning.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatButtonModule
    ]
})

export class OptionalWarningComponent {

  @Input()
  description: string;

  @Input()
  buttonText: string;

  @Output()
  buttonClicked = new EventEmitter<void>();
}
