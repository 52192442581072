export interface CdnDomain {
  checkBackendCertificate: boolean;
  clientId: string;
  clientServiceId: string;
  created: string;
  domainName: string;
  forceCache: boolean;
  forceCacheTime: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  geoIpCountries?: any;
  geoIpMode: string;
  id: string;
  imgApiKey: string;
  imgApiSalt: string;
  lastUpdate: string;
  locations: DomainLocation[];
  proxyDomain: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  remoteLogDestination?: any;
  remoteLogType: string;
  sslCertId: string;
  status: 'ACTIVE' | 'DISABLED' | 'SUSPENDED_ACTIVE' | 'SUSPENDED_DISABLED' | 'DELETED';
  webp: boolean;
}

export interface DomainLocation {
  id: number;
  name: string;
}

export interface CdnDomainStatistic {
  dateFrom: string;
  dateTill: string;
  traffic: number;
  hits: number;
  miss: number;
  ratio: number;
}

export interface CdnDomainAccessLog {
  date: string;
  link: string;
  linkHtml: string;
  type: 'ACCESS' | 'GOACCESS';
}

export enum CdnDomainStatuses {
  Active = 'ACTIVE',
  Disabled = 'DISABLED',
  SuspendedActive = 'SUSPENDED_ACTIVE',
  SuspendedDisabled = 'SUSPENDED_DISABLED',
  Deleted = 'DELETED'
}
