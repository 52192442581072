<div class="__wrap">

  <div class="__text">
    @if (!!description) {
      <div [innerHTML]="description"></div>
    }

    @if (!description) {
      <div>
        <ng-content />
      </div>
    }

  </div>

  @if (!!buttonText) {
    <div class="fx:pt-0-5">
      <button
        mat-flat-button
        (click)="buttonClicked.emit()">
        {{ buttonText }}
      </button>
    </div>
  }

</div>
