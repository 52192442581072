<zef-form
  [state]="state"
  (submit)="submit.emit()">

  <zef-form-field class="__form-field">
    <mat-form-field [subscriptSizing]="'dynamic'" color="accent">

      <mat-label>{{ translations?.editDomainForm.yourDomainLabel }}</mat-label>

      <input
        matInput
        type="text"
        [ngrxEnableFocusTracking]="true"
        [ngrxFormControlState]="state?.controls.backendDomain"
      />

    </mat-form-field>
  </zef-form-field>

  <zef-form-field class="__form-field">
    <mat-form-field [subscriptSizing]="'dynamic'" color="accent">

      <mat-label>{{ translations?.editDomainForm.locationsLabel }}</mat-label>

      <mat-select
        multiple
        [placeholder]="translations?.editDomainForm.locationsPlaceholder"
        [ngrxFormControlState]="state?.controls.locations"
        [ngrxEnableFocusTracking]="true">

        <mat-option *ngFor="let item of locations" [value]="item.id">{{ item.name }}</mat-option>

      </mat-select>

    </mat-form-field>
  </zef-form-field>

  <div
    class="__toggle-wrap  __toggle-wrap--extendable"
    [class.is-active]="state?.controls.forceCache.value">

    <zef-form-field
      class="__force-cache-field  __expandable-toggle-form-field"
      last
      [class.is-enabled]="state?.controls.forceCache.value"
      appearance="slide-toggle">

      <mat-slide-toggle
        [ngrxFormControlState]="state?.controls.forceCache"
        [ngrxEnableFocusTracking]="true">
        {{ translations?.editDomainForm.enforceCacheToggle }}
      </mat-slide-toggle>

      <zef-form-hints>{{ translations?.editDomainForm.enforceCacheHint }}</zef-form-hints>

    </zef-form-field>

    <zef-form-field
      last
      class="__force-cache-time-field  __form-field"
      *ngIf="state?.controls.forceCache.value">
      <mat-form-field [subscriptSizing]="'dynamic'" color="accent">

        <mat-label>{{ translations?.editDomainForm.cacheExpirationLabel }}</mat-label>

        <input
          type="number"
          matInput
          [placeholder]="translations?.editDomainForm.cacheExpirationPlaceholder"
          [ngrxEnableFocusTracking]="true"
          [ngrxFormControlState]="state?.controls.forceCacheTime"
        />

        <mat-hint>{{ translations?.editDomainForm.cacheExpirationHint }}</mat-hint>

      </mat-form-field>
    </zef-form-field>

  </div>

  <div class="__toggle-wrap">
    <zef-form-field appearance="slide-toggle">

      <mat-slide-toggle
        [ngrxFormControlState]="state?.controls.webp"
        [ngrxEnableFocusTracking]="true">
        {{ translations?.editDomainForm.webpConversionToggle }}
      </mat-slide-toggle>

      <zef-form-hints>
        {{ translations?.editDomainForm.webpConversionHint }} <br/>{{ translations?.editDomainForm.apiKey }}<span class="c-blurred">{{ apiKey }}</span> <br/>{{ translations?.editDomainForm.apiSalt }}<span class="c-blurred">{{ apiSalt }}</span>
      </zef-form-hints>

    </zef-form-field>
  </div>

  <div
    class="__toggle-wrap  __toggle-wrap--extendable  __remote-log-mode-field"
    [class.is-active]="state?.controls.enableRemoteLog.value">

    <zef-form-field
      class="__expandable-toggle-form-field"
      last
      appearance="slide-toggle">

      <mat-slide-toggle
        [ngrxFormControlState]="state?.controls.enableRemoteLog"
        [ngrxEnableFocusTracking]="true">
        {{ translations?.editDomainForm.remoteDestinationToggle }}
      </mat-slide-toggle>

      <zef-form-hints>
        {{ translations?.editDomainForm.remoteDestinationHint }}
      </zef-form-hints>

    </zef-form-field>

    <div class="__remote-log-fields" *ngIf="state?.controls.enableRemoteLog.value">

      <zef-form-field
        last
        class="__expandable-toggle-mid-form-field  __remote-log-mode-field"
        appearance="button-toggle">

        <zef-form-label>
          {{ translations?.editDomainForm.modeLabel }}
        </zef-form-label>

        <mat-button-toggle-group
          [ngrxFormControlState]="state?.controls.remoteLogType"
          [ngrxEnableFocusTracking]="true">

          <mat-button-toggle [value]="item" *ngFor="let item of remoteLogTypes">
            {{ remoteLogTypesTranslations?.enums[item] }}
          </mat-button-toggle>

        </mat-button-toggle-group>

      </zef-form-field>

      <zef-form-field
        last
        class="__remote-log-destination-field  __form-field">
        <mat-form-field [subscriptSizing]="'dynamic'" color="accent">

          <mat-label>
          {{ translations?.editDomainForm.logDestinationLabel }}
          </mat-label>

          <input
            matInput
            [ngrxEnableFocusTracking]="true"
            [placeholder]="translations?.editDomainForm.logProtocolMap[state?.controls.remoteLogType.value]"
            [ngrxFormControlState]="state?.controls.remoteLogDestination"
          />

        </mat-form-field>
      </zef-form-field>

    </div>

  </div>

  <div
    class="__toggle-wrap  __toggle-wrap--extendable  __geo-ip-mode-field"
    [class.is-active]="state?.controls.enableGeoIp.value">

    <zef-form-field
      class="__expandable-toggle-form-field"
      last
      appearance="slide-toggle">

      <mat-slide-toggle
        [ngrxFormControlState]="state?.controls.enableGeoIp"
        [ngrxEnableFocusTracking]="true">
        {{ translations?.editDomainForm.geoIpRestrictionsToggle }}
      </mat-slide-toggle>

      <zef-form-hints>
        {{ translations?.editDomainForm.geoIpRestrictionsHint }}
      </zef-form-hints>

    </zef-form-field>

    <div class="__geo-ip-fields" *ngIf="state?.controls.enableGeoIp.value">

      <zef-form-field
        last
        class="__expandable-toggle-mid-form-field  __geo-ip-mode-field"
        appearance="button-toggle">

        <zef-form-label>
          {{ translations?.editDomainForm.modeLabel }}
        </zef-form-label>

        <mat-button-toggle-group
          [ngrxFormControlState]="state?.controls.geoIpMode"
          [ngrxEnableFocusTracking]="true">

          <mat-button-toggle value="BLACKLIST">
            {{ translations?.editDomainForm.blacklistCountries }}
          </mat-button-toggle>

          <mat-button-toggle value="WHITELIST">
            {{ translations?.editDomainForm.whitelistCountries }}
          </mat-button-toggle>

        </mat-button-toggle-group>

      </zef-form-field>

      <zef-form-field last class="__country-select-form-field">
        <mat-form-field [subscriptSizing]="'dynamic'">

          <mat-label>
            {{ translations?.editDomainForm.countriesLabel }}
          </mat-label>

          <mat-select
            [ngrxFormControlState]="state?.controls.geoIpCountries"
            [ngrxEnableFocusTracking]="true"
            [placeholder]="state?.controls.geoIpMode.value === 'BLACKLIST'
              ? translations?.editDomainForm.selectBlockedCountries
              : translations?.editDomainForm.selectAccessedCountries"
            multiple>

            <mat-select-trigger>
              <mat-chip-listbox>
                <mat-chip
                  *ngFor="let code of state?.controls.geoIpCountries.value.value"
                  (removed)="countryItemRemoved.emit(code)"
                  [removable]="true">
                  {{ countryMap[code]?.nameJsonObject[activeLangCode] }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
              </mat-chip-listbox>
            </mat-select-trigger>

            <mat-option
              *ngFor="let country of countryList"
              [value]="country.id">
              {{ country.nameJsonObject[activeLangCode] }}
            </mat-option>

          </mat-select>

        </mat-form-field>
      </zef-form-field>

    </div>

  </div>

  <ng-content></ng-content>

</zef-form>
